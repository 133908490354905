import React, { useState } from 'react';
import logoEmpresa from './assets/logoEmpresa.png';
import backgroundImage from './assets/background.png'; // Asegúrate de reemplazar esto con la ruta de tu imagen de fondo.
import { useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [emailVal, setEmailVal] = useState('');
  const [constrasenaVal, setContrasenaVal] = useState('');
  const [errors, setErrors] = useState({ emailVal: '', constrasenaVal: '' });

  const handleLogin = async () => {
    // Captura los valores de los inputs
    const email = document.querySelector('input[type="text"]').value;
    const password = document.querySelector('input[type="password"]').value;

    try {
      const response = await fetch(`https://api.vittamaq.cl/login.php?email=${email}&password=${password}`);
      const data = await response.json();
      const newErrors = {};

      if (!emailVal) {
        newErrors.emailVal = 'Debe ingresar su e-mail.';
        setErrors(newErrors);
      } else if (!constrasenaVal) {
        newErrors.constrasenaVal = 'Debe ingresar su contraseña.';
        setErrors(newErrors);
      } else {
        if (data && data.email && data.id_usuario) {
          navigate('/dashboard', { state: data });
        } else /* if (data === false) */ {
          alert('Email o contraseña mal escritos, intente nuevamente.');
        }
      }
    } catch (error) {
      alert('Hubo un error al intentar iniciar sesión. Por favor, inténtalo de nuevo.');
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="item-flex">
        <div className="form-signin">
          <img src={logoEmpresa} alt="Logo de la empresa" />
          <h4 className="mb-2">Iniciar Sesión</h4>
          <p>Ingrese sus credenciales de acceso:</p>
          <div className="form-floating w-100 mb-3">
            <input type="text" id="emailLogin" className="form-control" placeholder="E-Mail" onChange={(e) => setEmailVal(e.target.value)} onKeyDown={handleKeyDown}/>
            <label htmlFor="emailLogin">E-Mail</label>
            <p className="mt-1 mb-0 text-red"><small>{errors.emailVal}</small></p>
          </div>
          <div className="form-floating w-100 mb-4">
            <input type="password" id="claveLogin" className="form-control" placeholder="Contraseña" onChange={(e) => setContrasenaVal(e.target.value)} onKeyDown={handleKeyDown}/>
            <label htmlFor="claveLogin">Contraseña</label>
            <p className="mt-1 mb-0 text-red"><small>{errors.constrasenaVal}</small></p>
          </div>
          <button className="btn btn-yellow btn-lg w-100" onClick={handleLogin}>Ingresar</button>
        </div>
      </div>
      <div className="item-flex d-none d-lg-flex h-100 pt-5 pe-5 pb-5">
        <img src={backgroundImage} className="img-fluid rounded-4" alt="Fondo" />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row', // Cambiado de 'column' a 'row'
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    gap: '20px',
    padding: '20px',
  },
  loginContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  imageContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundImage: {
    width: '70%',
    height: '70%',
    objectFit: 'cover', // Esto hará que la imagen mantenga su aspecto mientras llena el contenedor
  },
  image: {
    width: '250px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  input: {
    width: 320,
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    width: 280,
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#e84424',
    color: 'white',
  },
};

export default Login;
