import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import './App.css';
import setDayWithOptions from "date-fns/esm/fp/setDayWithOptions/index.js";

function Servicios() {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataLogin, setDataLogin] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [isModalServicioOpen, setModalServicioOpen] = useState(false);
  const [maquinariaOptions, setMaquinariaOptions] = useState([]);
  const [maquinariaSelected, setMaquinariaSelected] = useState([]);
  const [unidadesMedidas, setUnidadesMedidas] = useState([]);

  const [formServicioData, setFormServicioData] = useState({
    id_maquinaria: "",
    maquinaria_iniciales: "",
    id_tipo_maquinaria: "",
    id_tipo_arriendo: "",
    cantidad_minima: "",
    id_unidad_medida: "",
    glosa_servicio: "",
    valor_unitario_neto: "",
    item_orden: "",
    porcentaje_descuento_maximo: "",
    porcentaje_aumento_maximo: "",
    id_usuario: ""
  });

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerMaquinarias();
      obtenerServicios(1);
      obtenerUnidadesMedidas();
      setFormServicioData({
        ...formServicioData,
        ["id_usuario"]: receivedData.id_usuario
      });
    }
  }, [location]);

  const obtenerMaquinarias = async () => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/maquinaria.php`
      );
      const data = await response.json();

      if (data && data[0].id_maquinaria) {
        setMaquinariaOptions(data);
        }
    } catch (error) {
      setMaquinariaOptions([]);
    }
  };

  const obtenerServicios = async (idMaquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/servicio.php?id_maquinaria=${idMaquinaria}&todos=1`
      );
      const data = await response.json();

      if (data && data[0].id_servicio) {
        setServicios(data);
      }
    } catch (error) {
      //alert("No hay servicios para mostrar.");
      setServicios([]);
    }
  };

  const obtenerUnidadesMedidas = async () => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/unidad_medida.php?`
      );
      const data = await response.json();

      if (data && data[0].id_unidad_medida) {
        setUnidadesMedidas(data);
      }
    } catch (error) {
      //alert("No hay servicios para mostrar.");
      setUnidadesMedidas([]);
    }
  };

  const [habilitaInputEquipo, setHabilitaInputEquipo] = useState(true);
  const handleFormServicioData = async (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'id_maquinaria':
        setMaquinariaSelected(value);
  
        if (value === "") {
          setFormServicioData({
            ...formServicioData,
            id_maquinaria: "",
            id_tipo_maquinaria: "",
          });
          setTipoMaquinariaOptions([]);
          setArriendoOptions([]);
          setHabilitaInputEquipo(true);
        } else {
          let maquinariaIniciales = obtenerInicialesMaquinaria(value);

          await obtenerTipoMaquinaria(value);
          await obtenerArriendosMaquinaria(value);
    
          if (value === "3") {
            setHabilitaInputEquipo(false);
            setFormServicioData({
              ...formServicioData,
              id_maquinaria: '3',
              maquinaria_iniciales: maquinariaIniciales,
              id_tipo_maquinaria: '0'
            });
          } else {
            setFormServicioData({
              ...formServicioData,
              id_maquinaria: value,
              maquinaria_iniciales: maquinariaIniciales,
            });
            setHabilitaInputEquipo(true);
          }
        }
        break;
      case 'item_orden':
        // Valida que el primer carácter sea una letra y el segundo un número
        const regex = /^[A-Za-z][0-9]*$/;

        if (formServicioData.id_maquinaria === "") {
          alert('Debe seleccionar una maquinaria');
        } else if (regex.test(value) || value === "") {

          setFormServicioData({
            ...formServicioData,
            [name]: value,
          });
        }
        break;
      default:
        if (name === "glosa_servicio") {
          setFormServicioData({
            ...formServicioData,
            [name]: value,
          });
        } else {
          // Valida solo numeros y solo 1 punto
          const regexNumeros = /^[0-9]*\.?[0-9]*$/;

          if (regexNumeros.test(value)) {
            setFormServicioData({
              ...formServicioData,
              [name]: value,
            });
          }
        }
    }
  };

  const closeModalServicio = () => {
    setModalServicioOpen(false);
  };

  const handleAddServicioClick = () => {
    setFormServicioData({
      id_maquinaria: "",
      maquinaria_iniciales: "",
      id_tipo_maquinaria: "",
      id_tipo_arriendo: "",
      cantidad_minima: "",
      id_unidad_medida: "",
      glosa_servicio: "",
      valor_unitario_neto: "",
      item_orden: "",
      porcentaje_descuento_maximo: "",
      porcentaje_aumento_maximo: "",
      id_usuario: dataLogin.id_usuario
    });
    setMaquinariaSelected({});
    setModalServicioOpen(true);
  };

  const handleSubmitServicio = async (event) => {
    event.preventDefault();

    if (formServicioData.glosa_servicio.trim() !== '') {
      formServicioData.id_tipo_maquinaria = 0;
      formServicioData.id_tipo_arriendo = 0;
    }

    try {
      const esFormularioValido = validaFormularioServicios();

      if (esFormularioValido) {
        formServicioData.item_orden = `${formServicioData.maquinaria_iniciales}-${formServicioData.item_orden}`;

        const response = await fetch(
          `https://api.vittamaq.cl/servicio.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formServicioData),
          }
        );
  
        const data = await response.json();
  
        if (data.message === "Servicio agregado con éxito.") {
          alert("Servicio agregado con éxito.");
          setFormServicioData({
            id_maquinaria: "",
            maquinaria_iniciales: "",
            id_tipo_maquinaria: "",
            id_tipo_arriendo: "",
            cantidad_minima: "",
            id_unidad_medida: "",
            glosa_servicio: "",
            valor_unitario_neto: "",
            item_orden: "",
            porcentaje_descuento_maximo: "",
            porcentaje_aumento_maximo: "",
            id_usuario: dataLogin.id_usuario
          });
          navigate("/servicios", { state: dataLogin });
          closeModalServicio();
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      //console.log(error);
      alert("Error al agregar Servicio.");
    }
  };

  const [tipoMaquinariaOptions, setTipoMaquinariaOptions] = useState([]);
  const obtenerTipoMaquinaria = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/tipo_maquinaria.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();

      if (data && data[0].id_tipo_maquinaria) {
        setTipoMaquinariaOptions(data);
      }
    } catch (error) {
      setTipoMaquinariaOptions([]);
    }
  };

  const [arriendoOptions, setArriendoOptions] = useState([]);
  const obtenerArriendosMaquinaria = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/tipo_arriendo.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();

      if (data && data[0].id_tipo_arriendo) {
        setArriendoOptions(data);
      }
    } catch (error) {
      setArriendoOptions([]);
    }
  };

  const obtenerInicialesMaquinaria = (idMaquinaria) => {
    const maquinaria = maquinariaOptions.find(item => item.id_maquinaria === Number(idMaquinaria));

    return maquinaria.iniciales;
  };

  const [activeTab, setActiveTab] = useState('tab-combinado');
  const handleTabClick = async (tab) => {
    setFormServicioData({
      id_maquinaria: "",
      maquinaria_iniciales: "",
      id_tipo_maquinaria: "",
      id_tipo_arriendo: "",
      cantidad_minima: "",
      id_unidad_medida: "",
      glosa_servicio: "",
      valor_unitario_neto: "",
      item_orden: "",
      porcentaje_descuento_maximo: "",
      porcentaje_aumento_maximo: "",
      id_usuario: dataLogin.id_usuario
    });
    setMaquinariaSelected({});
    setTipoMaquinariaOptions([]);
    setArriendoOptions([]);
    setActiveTab(tab);
  };

  const modalComponentServicio = (
    <Modal
      isOpen={isModalServicioOpen}
      onRequestClose={closeModalServicio}
      contentLabel="ModalServicio"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Servicio</h4>
            <button onClick={closeModalServicio} className="btn-close"></button>
          </div>
          <div className="modal-body pt-0">
            <ul className="nav nav-underline nav-justified mb-4">
              <li className="nav-item">
                <button
                  name="tab-combinado"
                  className={`nav-link ${activeTab === 'tab-combinado' ? 'active' : ''}`}
                  onClick={() => handleTabClick('tab-combinado')}
                >
                  Combinado
                </button>
              </li>
              <li className="nav-item">
                <button
                  name="tab-glosa"
                  className={`nav-link ${activeTab === 'tab-glosa' ? 'active' : ''}`}
                  onClick={() => handleTabClick('tab-glosa')}
                >
                  Glosa
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div className={`tab-pane ${activeTab === 'tab-combinado' ? 'active' : ''}`}>
                {maquinariaOptions ? (
                  <div className="mb-3">
                    <label className="form-label">Maquinaria</label>
                    <select className="form-select" name="id_maquinaria" value={maquinariaSelected || formServicioData.id_maquinaria} onChange={handleFormServicioData}>
                      <option value="">Seleccione Maquinaria</option>
                      {maquinariaOptions.map((maquinaria) => (
                        <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                      ))}
                    </select>
                  </div>
                  ) : (
                  <div className="mb-3">
                    <label className="form-label">Maquinaria</label>
                    <select className="form-select" name="id_maquinaria">
                      <option value="">No se encontraron maquinarias</option>
                    </select>
                  </div>
                )}
                <div className="row row-cols-md-2 mb-3">
                  {tipoMaquinariaOptions ? (
                    <div className="col">
                      <label className="form-label">{maquinariaSelected === "1" ? "Tipo de Bomba Hormigón" : maquinariaSelected === "2" ? "Toneladas" : "Tipo de Maquinaria"}</label>
                      <select className="form-select" name="id_tipo_maquinaria" value={formServicioData.id_tipo_maquinaria} onChange={handleFormServicioData}>
                        <option value="">Seleccione {maquinariaSelected === "1" ? "Tipo de Bomba Hormigón" : maquinariaSelected === "2" ? "Toneladas" : "Tipo de Maquinaria"}</option>
                        {tipoMaquinariaOptions.map((equipo) => (
                          <option key={equipo.id_tipo_maquinaria} value={equipo.id_tipo_maquinaria}>{equipo.tipo_maquinaria}</option>
                        ))}
                      </select>
                    </div>
                    ) : (
                    <div className="col">
                      <label className="form-label">Equipo</label>
                      <select className="form-select" name="id_tipo_maquinaria" disabled={habilitaInputEquipo ? false : true}>
                        <option value="">No se encontraron equipos asociados</option>
                      </select>
                    </div>
                  )}
                  {arriendoOptions ? (
                    <div className="col">
                      <label className="form-label">Tipo de Arriendo</label>
                      <select className="form-select" name="id_tipo_arriendo" value={formServicioData.id_tipo_arriendo} onChange={handleFormServicioData}>
                        <option value="">Seleccione Arriendo</option>
                        {arriendoOptions.map((arriendo) => (
                          <option key={arriendo.id_tipo_arriendo} value={arriendo.id_tipo_arriendo}>{arriendo.descripcion}</option>
                        ))}
                      </select>
                    </div>
                    ) : (
                    <div className="col">
                      <label className="form-label">Tipo de Arriendo</label>
                      <select className="form-select" name="id_tipo_arriendo">
                        <option value="">No se encontraron arriendos asociados</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className={`tab-pane ${activeTab === 'tab-glosa' ? 'active' : ''}`}>
                {maquinariaOptions ? (
                  <div className="mb-3">
                    <label className="form-label">Maquinaria</label>
                    <select className="form-select" name="id_maquinaria" value={maquinariaSelected || formServicioData.id_maquinaria} onChange={handleFormServicioData}>
                      <option value="">Seleccione Maquinaria</option>
                      {maquinariaOptions.map((maquinaria) => (
                        <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                      ))}
                    </select>
                  </div>
                  ) : (
                  <div className="mb-3">
                    <label className="form-label">Tipo Maquinaria</label>
                    <select className="form-select" name="id_maquinaria">
                      <option value="">No se encontraron maquinarias</option>
                    </select>
                  </div>
                )}
                <div className="mb-3">
                  <label className="form-label">Descripción (Glosa)</label>
                  <input
                    className="form-control"
                    name="glosa_servicio"
                    type="text"
                    value={formServicioData.glosa_servicio} 
                    onChange={handleFormServicioData}
                  />
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Cantidad Mínima</label>
                <input
                  className="form-control"
                  name="cantidad_minima"
                  type="text"
                  value={formServicioData.cantidad_minima} 
                  onChange={handleFormServicioData}
                />
              </div>
              {unidadesMedidas ? (
                <div className="col">
                  <label className="form-label">Unidad de Medida</label>
                  <select className="form-select" name="id_unidad_medida" value={formServicioData.id_unidad_medida} onChange={handleFormServicioData}>
                    <option value="">Seleccione Medida</option>
                    {unidadesMedidas.map((medida) => (
                      <option key={medida.id_unidad_medida} value={medida.id_unidad_medida}>{medida.descripcion}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Unidad de Medida</label>
                  <select className="form-select" name="id_unidad_medida">
                    <option value="">No se encontraron unidades de medidas</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Valor Unitario o Neto {maquinariaSelected === '2' ? '$' : 'UF'}</label>
                <input
                  className="form-control"
                  name="valor_unitario_neto"
                  type="text"
                  value={formServicioData.valor_unitario_neto} 
                  onChange={handleFormServicioData}
                />
              </div>
              <div className="col">
                <label className="form-label">Item Orden</label>
                <input
                  className="form-control"
                  name="item_orden"
                  type="text"
                  placeholder="Ejemplo: A1"
                  value={formServicioData.item_orden} 
                  onChange={handleFormServicioData}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col">
                <label className="form-label">% Descuento Máximo</label>
                <input
                  className="form-control"
                  name="porcentaje_descuento_maximo"
                  type="text"
                  value={formServicioData.porcentaje_descuento_maximo} 
                  onChange={handleFormServicioData}
                />
              </div>
              <div className="col">
                <label className="form-label">% Aumento Máximo</label>
                <input
                  className="form-control"
                  name="porcentaje_aumento_maximo"
                  type="text"
                  value={formServicioData.porcentaje_aumento_maximo} 
                  onChange={handleFormServicioData}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitServicio}><i className="bi bi-floppy"></i> Guardar Servicio</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const validaFormularioServicios = () => {
    if (formServicioData.id_maquinaria === "") {
      alert('Debe seleccionar un tipo de maquinaria');
      return false;
    }

    if (activeTab === "tab-combinado") {
      //Si es distinto de grúa torre, solicita equipo
      if (formServicioData.id_maquinaria != '3') {
        if (formServicioData.id_tipo_maquinaria === "") {
          alert(`Debe seleccionar ${formServicioData.id_maquinaria === '1' ? "un Tipo de Bomba Hormigón" : formServicioData.id_maquinaria === '2' ? "las Toneladas" : "un Tipo de Maquinaria"}`);
          return false;
        }
      }

      if (formServicioData.id_tipo_arriendo === "") {
        alert('Debe seleccionar un tipo de arriendo');
        return false;
      }
    } else {
      if (formServicioData.glosa_servicio === "") {
        alert('Debe ingresar una descripción (Glosa)');
        return false;
      }
    }

    if (formServicioData.cantidad_minima === "") {
      alert('Debe ingresar una cantidad mínima');
      return false;
    }

    if (formServicioData.id_unidad_medida === "") {
      alert('Debe seleccionar una unidad de medida');
      return false;
    }

    if (formServicioData.valor_unitario_neto === "") {
      alert('Debe ingresar un valor unitario neto');
      return false;
    }

    if (formServicioData.item_orden === "") {
      alert('Debe ingresar un item de orden');
      return false;
    }

    // Valida el formato del item de orden
    const regex = /^[A-Za-z][0-9]+$/;
    if (!regex.test(formServicioData.item_orden)) {
      alert('Debe ingresar un item de orden con el siguiente formato (Ej.): A1');
      return false;
    }

    // Valida que el item no exista
    /* const itemOrden = `${formServicioData.maquinaria_iniciales}-${formServicioData.item_orden}`;
    const existeItemOrden = !!servicios.find(servicio => servicio.item_orden === itemOrden);
    if (existeItemOrden) {
      alert('El item de orden ingresado ya se encuentra registrado');
      return false;
    } */

    if (formServicioData.porcentaje_descuento_maximo === "") {
      alert('Debe ingresar un porcentaje de descuento máximo');
      return false;
    }

    if (formServicioData.porcentaje_aumento_maximo === "") {
      alert('Debe ingresar un porcentaje de descueno máximo');
      return false;
    }

    return true;
  }

  //Filtro Por Servicio
  const [maquinariaFiltro, setMaquinariaFiltro] = useState('1');
  const handleFiltroMaquinariaData = (event) => {
    setMaquinariaFiltro(event.target.value);
    obtenerServicios(event.target.value)
  };

  //Filtro Servicios
  const [filtroServicios, setFiltroServicios] = useState('');
  const manejarCambioFiltro = (event) => {
    setFiltroServicios(event.target.value);
  };

  const serviciosFiltrados = servicios.filter((item) =>
    item.glosa_servicio.toLowerCase().includes(filtroServicios.toLowerCase())
  );


/* Edición Servicios */
const [isModalOpen, setModalOpen] = useState(false);
const [isSecondModalOpen, setSecondModalOpen] = useState(false);
const [modalContent, setModalContent] = useState(null);
const [secondModalContent, setSecondModalContent] = useState(null);

const closeModal = () => {
  setModalOpen(false);
  setModalContent(null);
};

const closeSecondModal = () => {
  setSecondModalOpen(false);
  setSecondModalContent(null);
};

const handleEditButtonClick = (data) => {
  setModalOpen(true);
  setModalContent(<EditarServicioModalContent datos={data} />);
};

function EditarServicioModalContent({ datos }) {
  const navigate = useNavigate();
  const [tipoMaquinaria, setTipoMaquinaria] = useState([]);
  const [tipoArriendo, setTipoArriendo] = useState([]);
  const [activeTabEdit, setActiveTabEdit] = useState('');
  
  useEffect(() => {
    obtenerTiposMaquinaria(datos.id_maquinaria);
    obtenerArriendosMaquinaria(datos.id_maquinaria);
  }, [datos.id_maquinaria]);

  useEffect(() => {
    if (Number(datos.id_tipo_maquinaria) !== 0) {
      setActiveTabEdit('tab-combinado');
    } else {
      setActiveTabEdit('tab-glosa');
    }
  }, [datos.id_tipo_maquinaria]);

  const obtenerTiposMaquinaria = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/tipo_maquinaria.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();

      if (data && data[0].id_tipo_maquinaria) {
        setTipoMaquinaria(data);
      }
    } catch (error) {
      setTipoMaquinaria([]);
    }
  };

  const obtenerArriendosMaquinaria = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/tipo_arriendo.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();

      if (data && data[0].id_tipo_arriendo) {
        setTipoArriendo(data);
      }
    } catch (error) {
      setTipoArriendo([]);
    }
  };

  const [formServicioEditData, setFormServicioEditData] = useState({
    id_maquinaria: datos.id_maquinaria || "",
    id_servicio: datos.id_servicio || "",
    id_tipo_maquinaria: datos.id_tipo_maquinaria || "",
    descripcion: datos.descripcion || "",
    id_tipo_arriendo: datos.id_tipo_arriendo || "",
    tipo_arriendo: datos.tipo_arriendo || "",
    nombre_maquinaria: datos.nombre_maquinaria || "",
    iniciales: datos.iniciales || "",
    cantidad_minima: datos.cantidad_minima || "",
    id_unidad_medida: datos.id_unidad_medida || "",
    unidad_medida: datos.unidad_medida || "",
    glosa_servicio: datos.glosa_servicio || "",
    valor_unitario_neto: datos.valor_unitario_neto || "",
    item_orden: datos.item_orden.split('-')[1] || "",
    porcentaje_descuento_maximo: datos.porcentaje_descuento_maximo || "",
    porcentaje_aumento_maximo: datos.porcentaje_aumento_maximo || "",
    id_estado_servicio: datos.id_estado_servicio.toString() || "",
    estado: datos.estado || "",
    id_usuario_actualizacion: dataLogin.id_usuario
  });

  const handleFormServicioEditData = async (e) => {
    switch (e.target.name) {
      case 'id_maquinaria':
        setFormServicioEditData({
          ...formServicioEditData,
          [e.target.name]: e.target.value,
        });
        await obtenerTipoMaquinaria(e.target.value);
        await obtenerArriendosMaquinaria(e.target.value);
        break;
      case 'id_tipo_maquinaria':
        if(e.target.value === "") {
          alert(`Debe seleccionar ${formServicioEditData.id_maquinaria === 1 ? "un Tipo de Bomba Hormigón" : formServicioEditData.id_maquinaria === 2 ? "las Toneladas" : "un Tipo de Maquinaria"}`);
        } else {
          setFormServicioEditData({
            ...formServicioEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case 'item_orden':
        // Valida que el primer carácter sea una letra y el segundo un número
        const regex = /^[A-Za-z][0-9]*$/;

        if (regex.test(e.target.value) || e.target.value === "") {
          setFormServicioEditData({
            ...formServicioEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      default:
        if (e.target.name === "glosa_servicio") {
          setFormServicioEditData({
            ...formServicioEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          // Valida solo numeros y solo 1 punto
          const regexNumeros = /^[0-9]*\.?[0-9]*$/;

          if (regexNumeros.test(e.target.value)) {
            setFormServicioEditData({
              ...formServicioEditData,
              [e.target.name]: e.target.value,
            });
          }
        }
    }
  };

  const handleSubmitEditServicio = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioEditServicio();

      if (esFormularioValido) {
        formServicioEditData.item_orden = `${formServicioEditData.iniciales}-${formServicioEditData.item_orden}`;
        formServicioEditData.id_tipo_maquinaria = formServicioEditData.id_tipo_maquinaria ? formServicioEditData.id_tipo_maquinaria : 0;
        formServicioEditData.id_tipo_arriendo = formServicioEditData.id_tipo_arriendo ? formServicioEditData.id_tipo_arriendo : 0;

        const responseEditServicio = await fetch(
          `https://api.vittamaq.cl/servicio.php`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formServicioEditData),
          }
        );

        const dataEditServicio = await responseEditServicio.json();
        if (dataEditServicio.message === "Servicio modificado con éxito.") {
          setFormServicioEditData({
            id_maquinaria: "",
            id_servicio: "",
            id_tipo_maquinaria: "",
            descripcion: "",
            id_tipo_arriendo: "",
            tipo_arriendo: "",
            nombre_maquinaria: "",
            iniciales: "",
            cantidad_minima: "",
            id_unidad_medida: "",
            unidad_medida: "",
            glosa_servicio: "",
            valor_unitario_neto: "",
            item_orden: "",
            porcentaje_descuento_maximo: "",
            porcentaje_aumento_maximo: "",
            id_estado_servicio: "",
            estado: "",
            id_usuario_actualizacion: ""
          });

          alert("Servicio modificado con éxito.");
          closeModal();
          navigate("/servicios", { state: dataLogin });
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      //console.log(error)
      alert("Error al editar el servicio.");
    }
  }

  const validaFormularioEditServicio = () => {
    if (formServicioEditData.id_maquinaria === "") {
      alert('Debe seleccionar un tipo de maquinaria');
      return false;
    }

    if (activeTabEdit === "tab-combinado") {
      //Si es distinto de grúa torre, solicita equipo
      if (formServicioEditData.id_maquinaria != '3') {
        if (formServicioEditData.id_tipo_maquinaria === "") {
          alert(`Debe seleccionar ${formServicioEditData.id_maquinaria === '1' ? "un Tipo de Bomba Hormigón" : formServicioEditData.id_maquinaria === '2' ? "las Toneladas" : "un Tipo de Maquinaria"}`);
          return false;
        }
      }

      if (formServicioEditData.id_tipo_arriendo === "") {
        alert('Debe seleccionar un tipo de arriendo');
        return false;
      }
    } else {
      if (formServicioEditData.glosa_servicio === "") {
        alert('Debe ingresar una descripción (Glosa)');
        return false;
      }
    }

    if (formServicioEditData.cantidad_minima === "") {
      alert('Debe ingresar una cantidad mínima');
      return false;
    }

    if (formServicioEditData.id_unidad_medida === "") {
      alert('Debe seleccionar una unidad de medida');
      return false;
    }

    if (formServicioEditData.valor_unitario_neto === "") {
      alert('Debe ingresar un valor unitario neto');
      return false;
    }

    if (formServicioEditData.item_orden === "") {
      alert('Debe ingresar un item de orden');
      return false;
    }

    // Valida el formato del item de orden
    const regex = /^[A-Za-z][0-9]+$/;
    if (!regex.test(formServicioEditData.item_orden)) {
      alert('Debe ingresar un item de orden con el siguiente formato (Ej.): A1');
      return false;
    }

    // Valida que el item no exista
    /* const itemOrden = `${formServicioEditData.iniciales}-${formServicioEditData.item_orden}`;
    const existeItemOrden = !!servicios.find(servicio => servicio.item_orden === itemOrden);
    if (existeItemOrden) {
      alert('El item de orden ingresado ya se encuentra registrado');
      return false;
    } */

    if (formServicioEditData.porcentaje_descuento_maximo === "") {
      alert('Debe ingresar un porcentaje de descuento máximo');
      return false;
    }

    if (formServicioEditData.porcentaje_aumento_maximo === "") {
      alert('Debe ingresar un porcentaje de descueno máximo');
      return false;
    }

    return true;
  }

  /* Deshabilita Servicio */
  const handleDeshabilitarServicio = (idServicio, nombreServicio) => {
    setSecondModalOpen(true);
    setSecondModalContent(<ConfirmaDeshabilitarServicioModalContent id_servicio={idServicio} nombre_servicio={nombreServicio} />);
  };

  function ConfirmaDeshabilitarServicioModalContent ({ id_servicio, nombre_servicio }) {
    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Deshabilitar Servicio</h4>
            <button onClick={closeSecondModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <p className="fs-5">¿Está seguro que desea deshabilitar el servicio:<br/> <span className="text-red">{nombre_servicio}</span>?</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={(e) => handleSubmitDeshabilitarServicio(e, id_servicio)}><i className="bi bi-dash-circle"></i> Deshabilitar</button>
          </div>
        </div>
      </div>
    );
  }

  const handleSubmitDeshabilitarServicio = async (event, idServicio) => {
    event.preventDefault();

    try {
      const formDeshabilitaServicio = {
        id_servicio: idServicio,
        id_usuario_actualizacion: dataLogin.id_usuario
      };

      const responseDeshabilitarServicio = await fetch(
        `https://api.vittamaq.cl/servicio.php?deshabilita=1`,
        {
          method: "PUT",
          headers: {
            'Accept': 'application/json', // Indica que esperas una respuesta JSON
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formDeshabilitaServicio),
        }
      );

      const dataDeshabilitarServicio = await responseDeshabilitarServicio.json();
      if (dataDeshabilitarServicio.message === "Servicio deshabilitado con éxito.") {
        setFormServicioEditData({
          id_maquinaria: "",
          id_servicio: "",
          id_tipo_maquinaria: "",
          descripcion: "",
          id_tipo_arriendo: "",
          tipo_arriendo: "",
          nombre_maquinaria: "",
          iniciales: "",
          cantidad_minima: "",
          id_unidad_medida: "",
          unidad_medida: "",
          glosa_servicio: "",
          valor_unitario_neto: "",
          item_orden: "",
          porcentaje_descuento_maximo: "",
          porcentaje_aumento_maximo: "",
          id_estado_servicio: "",
          estado: "",
          id_usuario_actualizacion: ""
        });

        alert("Servicio deshabilitado con éxito.");
        closeSecondModal();
        closeModal();
        obtenerServicios();
        navigate("/servicios", { state: dataLogin });
      }
    } catch (error) {
      alert("Error al deshabilitar el servicio");
    }
  }

  /* Habilita Servicio */
  const handleHabilitarServicio = (idServicio, nombreServicio) => {
    setSecondModalOpen(true);
    setSecondModalContent(<ConfirmaHabilitarServicioModalContent id_servicio={idServicio} nombre_servicio={nombreServicio} />);
  };

  function ConfirmaHabilitarServicioModalContent ({ id_servicio, nombre_servicio }) {
    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Habilitar Servicio</h4>
            <button onClick={closeSecondModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <p className="fs-5">¿Está seguro que desea habilitar el servicio:<br/> <span className="text-green">{nombre_servicio}</span>?</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-success" onClick={(e) => handleSubmitHabilitarServicio(e, id_servicio)}><i className="bi bi-dash-circle"></i> Habilitar</button>
          </div>
        </div>
      </div>
    );
  }

  const handleSubmitHabilitarServicio = async (event, idServicio) => {
    event.preventDefault();

    try {
      const formHabilitaServicio = {
        id_servicio: idServicio,
        id_usuario_actualizacion: dataLogin.id_usuario
      };

      const responseHabilitarServicio = await fetch(
        `https://api.vittamaq.cl/servicio.php?habilita=1`,
        {
          method: "PUT",
          headers: {
            'Accept': 'application/json', // Indica que esperas una respuesta JSON
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formHabilitaServicio),
        }
      );

      const dataHabilitarServicio = await responseHabilitarServicio.json();
      if (dataHabilitarServicio.message === "Servicio habilitado con éxito.") {
        setFormServicioEditData({
          id_maquinaria: "",
          id_servicio: "",
          id_tipo_maquinaria: "",
          descripcion: "",
          id_tipo_arriendo: "",
          tipo_arriendo: "",
          nombre_maquinaria: "",
          iniciales: "",
          cantidad_minima: "",
          id_unidad_medida: "",
          unidad_medida: "",
          glosa_servicio: "",
          valor_unitario_neto: "",
          item_orden: "",
          porcentaje_descuento_maximo: "",
          porcentaje_aumento_maximo: "",
          id_estado_servicio: "",
          estado: "",
          id_usuario_actualizacion: ""
        });

        alert("Servicio habilitado con éxito.");
        closeSecondModal();
        closeModal();
        obtenerServicios();
        navigate("/servicios", { state: dataLogin });
      }
    } catch (error) {
      alert("Error al habilitar el servicio");
    }
  }

  return (
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="mb-0">Editar Servicio</h4>
          <button onClick={closeModal} className="btn-close"></button>
        </div>
        <div className="modal-body pt-0">
          <ul className="nav nav-underline nav-justified mb-4">
            <li className="nav-item">
              <button
                name="tab-combinado"
                className={`nav-link ${formServicioEditData.id_tipo_maquinaria !== null && formServicioEditData.id_tipo_maquinaria !== '' ? 'active' : 'disabled'}`}
              >
                Combinado
              </button>
            </li>
            <li className="nav-item">
              <button
                name="tab-glosa"
                className={`nav-link ${formServicioEditData.id_tipo_maquinaria === null || formServicioEditData.id_tipo_maquinaria === '' ? 'active' : 'disabled'}`}
              >
                Glosa
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div className={`tab-pane ${formServicioEditData.id_tipo_maquinaria !== null && formServicioEditData.id_tipo_maquinaria !== '' ? 'active' : ''}`}>
              {maquinariaOptions ? (
                <div className="mb-3">
                  <label className="form-label">Tipo Maquinaria</label>
                  <select className="form-select" name="id_maquinaria" value={formServicioEditData.id_maquinaria} onChange={handleFormServicioEditData} disabled>
                    <option value="">Seleccione Tipo de Maquinaria</option>
                    {maquinariaOptions.map((maquinaria) => (
                      <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="mb-3">
                  <label className="form-label">Maquinaria</label>
                  <select className="form-select" name="id_maquinaria">
                    <option value="">No se encontraron maquinarias</option>
                  </select>
                </div>
              )}
              <div className="row row-cols-md-2 mb-3">
                {tipoMaquinaria ? (
                  <div className="col">
                    <label className="form-label">{formServicioEditData.id_maquinaria === 1 ? "Tipo de Bomba Hormigón" : formServicioEditData.id_maquinaria === 2 ? "Toneladas" : "Tipo de Maquinaria"}</label>
                    <select className="form-select" name="id_tipo_maquinaria" value={formServicioEditData.id_tipo_maquinaria} onChange={handleFormServicioEditData}>
                      <option value="">{formServicioEditData.id_maquinaria === 1 ? "Seleccione Tipo de Bomba Hormigón" : formServicioEditData.id_maquinaria === 2 ? "Seleccione Toneladas" : "Seleccione Tipo de Maquinaria"}</option>
                      {tipoMaquinaria.map((tipo_maquinaria) => (
                        <option key={tipo_maquinaria.id_tipo_maquinaria} value={tipo_maquinaria.id_tipo_maquinaria}>{tipo_maquinaria.tipo_maquinaria}</option>
                      ))}
                    </select>
                  </div>
                  ) : (
                  <div className="col">
                    <label className="form-label">Equipo</label>
                    <select className="form-select" name="id_tipo_maquinaria">
                      <option value="">No se encontraron equipos asociados</option>
                    </select>
                  </div>
                )}
                {tipoArriendo ? (
                  <div className="col">
                    <label className="form-label">Tipo de Arriendo</label>
                    <select className="form-select" name="id_tipo_arriendo" value={formServicioEditData.id_tipo_arriendo} onChange={handleFormServicioEditData}>
                      <option value="">Seleccione Arriendo</option>
                      {tipoArriendo.map((arriendo) => (
                        <option key={arriendo.id_tipo_arriendo} value={arriendo.id_tipo_arriendo}>{arriendo.descripcion}</option>
                      ))}
                    </select>
                  </div>
                  ) : (
                  <div className="col">
                    <label className="form-label">Tipo de Arriendo</label>
                    <select className="form-select" name="id_tipo_arriendo">
                      <option value="">No se encontraron arriendos asociados</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
            <div className={`tab-pane ${formServicioEditData.id_tipo_maquinaria === null || formServicioEditData.id_tipo_maquinaria === '' ? 'active' : ''}`}>
              {maquinariaOptions ? (
                <div className="mb-3">
                  <label className="form-label">Maquinaria</label>
                  <select className="form-select" name="id_maquinaria" value={formServicioEditData.id_maquinaria} onChange={handleFormServicioEditData} disabled>
                    <option value="">Seleccione Maquinaria</option>
                    {maquinariaOptions.map((maquinaria) => (
                      <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="mb-3">
                  <label className="form-label">Tipo Maquinaria</label>
                  <select className="form-select" name="id_maquinaria">
                    <option value="">No se encontraron maquinarias</option>
                  </select>
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Descripción (Glosa)</label>
                <input
                  className="form-control"
                  name="glosa_servicio"
                  type="text"
                  value={formServicioEditData.glosa_servicio} 
                  onChange={handleFormServicioEditData}
                />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 mb-3">
            <div className="col">
              <label className="form-label">Cantidad Mínima</label>
              <input
                className="form-control"
                name="cantidad_minima"
                type="text"
                value={formServicioEditData.cantidad_minima} 
                onChange={handleFormServicioEditData}
              />
            </div>
            {unidadesMedidas ? (
              <div className="col">
                <label className="form-label">Unidad de Medida</label>
                <select className="form-select" name="id_unidad_medida" value={formServicioEditData.id_unidad_medida} onChange={handleFormServicioEditData}>
                  <option value="">Seleccione Medida</option>
                  {unidadesMedidas.map((medida) => (
                    <option key={medida.id_unidad_medida} value={medida.id_unidad_medida}>{medida.descripcion}</option>
                  ))}
                </select>
              </div>
              ) : (
              <div className="col">
                <label className="form-label">Unidad de Medida</label>
                <select className="form-select" name="id_unidad_medida">
                  <option value="">No se encontraron unidades de medidas</option>
                </select>
              </div>
            )}
          </div>
          <div className="row row-cols-1 row-cols-md-2 mb-3">
            <div className="col">
              <label className="form-label">Valor Unitario o Neto {formServicioEditData.id_maquinaria === 2 ? '$' : 'UF'}</label>
              <input
                className="form-control"
                name="valor_unitario_neto"
                type="text"
                value={formServicioEditData.valor_unitario_neto} 
                onChange={handleFormServicioEditData}
              />
            </div>
            <div className="col">
              <label className="form-label">Item Orden</label>
              <input
                className="form-control"
                name="item_orden"
                type="text"
                placeholder="Ejemplo: A1"
                value={formServicioEditData.item_orden} 
                onChange={handleFormServicioEditData}
              />
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col">
              <label className="form-label">% Descuento Máximo</label>
              <input
                className="form-control"
                name="porcentaje_descuento_maximo"
                type="text"
                value={formServicioEditData.porcentaje_descuento_maximo} 
                onChange={handleFormServicioEditData}
              />
            </div>
            <div className="col">
              <label className="form-label">% Aumento Máximo</label>
              <input
                className="form-control"
                name="porcentaje_aumento_maximo"
                type="text"
                value={formServicioEditData.porcentaje_aumento_maximo} 
                onChange={handleFormServicioEditData}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {formServicioEditData.id_estado_servicio === "1" ? (
            <button className="btn btn-outline-danger" onClick={(e) => {
              const nombreServicio = formServicioEditData.glosa_servicio || formServicioEditData.nombre_maquinaria + " " + formServicioEditData.descripcion + " " + formServicioEditData.tipo_arriendo;
              handleDeshabilitarServicio(formServicioEditData.id_servicio, nombreServicio);
            }}>
              <i className="bi bi-dash-circle"></i> Deshabilitar
            </button>
          ) : formServicioEditData.id_estado_servicio === "2" && (
            <button className="btn btn-outline-success" onClick={(e) => {
              const nombreServicio = formServicioEditData.glosa_servicio || formServicioEditData.nombre_maquinaria + " " + formServicioEditData.descripcion + " " + formServicioEditData.tipo_arriendo;
              handleHabilitarServicio(formServicioEditData.id_servicio, nombreServicio);
            }}>
              <i className="bi bi-check-circle"></i> Habilitar
            </button>
          )}
          <button className="btn btn-yellow" onClick={handleSubmitEditServicio}><i className="bi bi-floppy"></i> Guardar</button>
        </div>
      </div>
    </div>
  );
}

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>
      <Modal
        isOpen={isSecondModalOpen}
        onRequestClose={closeSecondModal}
        contentLabel="Modal"
        className="modal"
      >
        {secondModalContent}
      </Modal>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <div className="d-flex align-items-center">
                    <h4 className="me-3">Servicios</h4>
                  </div>
                  <div className="d-flex flex-md-row gap-md-2">
                    <select className="form-select form-select-width" name="id_maquinaria" value={maquinariaFiltro} onChange={handleFiltroMaquinariaData}>
                      {maquinariaOptions.map((maquinaria) => (
                        <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                      ))}
                    </select>
                    <div className="input-group w-auto">
                      <span className="input-group-text" id="search-servicios">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Servicio..." aria-describedby="search-servicios" onChange={manejarCambioFiltro}/>
                    </div>
                    <button className="btn btn-yellow" onClick={handleAddServicioClick}>
                      <i className="bi-plus-circle"></i> Agregar Servicio
                    </button>
                  </div>
                </div>
                <div className="card-body card-body-fh p-0">
                  <div className="table-responsive">
                    <table className="table table-hover mb-0">
                      <thead>
                        <tr className="table-active border-top border-bottom">
                          <th scope="col" className="ps-3">Item</th>
                          <th scope="col">Nombre Servicio</th>
                          <th scope="col">Cantidad Mínima</th>
                          <th scope="col">Unidad Medida</th>
                          <th scope="col">Valor Unitario Neto {maquinariaFiltro != "2" ? "(UF)" : "$"}</th>
                          <th scope="col">Descuento Máximo</th>
                          <th scope="col">Aumento Máximo</th>
                          <th scope="col">Estado</th>
                          <th>Edición</th>
                        </tr>
                      </thead>
                      <tbody>
                      {serviciosFiltrados.length > 0 ? (
                        serviciosFiltrados.map((servicio, index) => (
                          <tr key={servicio.id_servicio}>
                            <td className="ps-3">
                              {servicio.item_orden.split('-')[1]}
                            </td>
                            <td>
                              {
                                servicio.glosa_servicio !== null && servicio.glosa_servicio !== '' ? (
                                  servicio.glosa_servicio
                                ) : (
                                  servicio.id_maquinaria !== 1 && servicio.id_maquinaria !== 2 && servicio.id_tipo_maquinaria !== 0 ? (
                                    servicio.nombre_maquinaria + " - " + servicio.tipo_arriendo
                                  ) : (
                                    servicio.nombre_maquinaria + " - " + servicio.tipo_maquinaria + " - " + servicio.tipo_arriendo
                                  )
                                )
                              }
                            </td>
                            <td>{Math.trunc(servicio.cantidad_minima)}</td>
                            <td>{servicio.unidad_medida}</td>
                            <td>
                              {maquinariaFiltro === "1" ? servicio.valor_unitario_neto : maquinariaFiltro === "2" ? parseInt(servicio.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') : parseFloat(servicio.valor_unitario_neto).toFixed(2)}
                            </td>
                            <td>{Math.trunc(servicio.porcentaje_descuento_maximo) + "%"}</td>
                            <td className="pe-3">{Math.trunc(servicio.porcentaje_aumento_maximo) + "%"}</td>
                            <td>
                              <span className={servicio.estado === 'Habilitado' ? 'text-green' : 'text-red'}>{servicio.estado}</span>
                            </td>
                            <td className="pe-3">
                              <button className="btn btn-outline-dark btn-sm w-100" onClick={() => handleEditButtonClick(servicio)}>
                                <i className="bi bi-pencil-square"></i> Editar
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className="text-center">No se encontraron servicios</td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalComponentServicio}
    </div>
  );
}

const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default Servicios;
