import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select';
import './App.css';

function Equipos() {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataLogin, setDataLogin] = useState([]);
  const [maquinariaOptions, setMaquinariaOptions] = useState([]);
  const [equipo, setEquipo] = useState([]);
  const [anoEquipo, setAnoEquipo] = useState([]);
  const [equipoSelected, setEquipoSelected] = useState([]);
  const [isModalEquipoOpen, setModalEquipoOpen] = useState(false);
  const [isModalEditarEquipoOpen, setModalEditarEquipoOpen] = useState(false);

  const [formEquipoData, setFormEquipoData] = useState({
    id_maquinaria: "",
    marca: "",
    modelo: "",
    numero_serie: "",
    id_ano_equipo: "",
    valor_minimo_arriendo: "",
    valor_arriendo_mensual: "",
    horometro: "",
    caracteristicas: "",
    id_usuario: ""
  });

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerMaquinarias();
      obtenerEquipo(1);
      obtenerAnoEquipo();
    }
  }, [location]);

  const obtenerMaquinarias = async () => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/maquinaria.php`
      );
      const data = await response.json();

      if (data && data[0].id_maquinaria) {
        setMaquinariaOptions(data);
        }
    } catch (error) {
      setMaquinariaOptions([]);
    }
  };

  const obtenerEquipo = async (idTipoMaquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/equipo.php?id_maquinaria=${idTipoMaquinaria}`
      );
      const data = await response.json();

      if (data && data[0].id_maquinaria) {
        setEquipo(data);
        }
    } catch (error) {
      setEquipo([]);
    }
  };

  const obtenerAnoEquipo = async () => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/ano_equipo.php`
      );
      const data = await response.json();

      if (data && data[0].id_ano_equipo) {
        setAnoEquipo(data);
        }
    } catch (error) {
      setAnoEquipo([]);
    }
  };

  //Filtro Por Equipo Tabla
  const [equipoFiltro, setEquipoFiltro] = useState('1');
  const handleFiltroEquipoData = (event) => {
    setEquipoFiltro(event.target.value);
    obtenerEquipo(event.target.value)
  };

  //Filtro Equipo
  const [filtroEquipo, setFiltroEquipo] = useState('');
  const manejarCambioFiltro = (event) => {
    setFiltroEquipo(event.target.value);
  };

  const equiposFiltrados = equipo.filter((item) =>
    item.modelo.toLowerCase().includes(filtroEquipo.toLowerCase())
  );

  const handleFormEquipoData = (e) => {
    switch (e.target.name) {
      case "id_maquinaria":
        setEquipoSelected(e.target.value);

        setFormEquipoData({
          ...formEquipoData,
          [e.target.name]: e.target.value,
        });
        break;
      case "valor_minimo_arriendo":
        if (/^\d*$/.test(e.target.value)) {
          setFormEquipoData({
            ...formEquipoData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "valor_arriendo_mensual":
        if (/^\d*$/.test(e.target.value)) {
          setFormEquipoData({
            ...formEquipoData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "horometro":
        if (/^\d*([\.]\d*)?$/.test(e.target.value)) {
          setFormEquipoData({
            ...formEquipoData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      default:
        setFormEquipoData({
          ...formEquipoData,
          [e.target.name]: e.target.value,
        });
    }
  };

  const closeModalEquipo = () => {
    setModalEquipoOpen(false);
  };
  
  const closeModalEditarEquipo = () => {
    setModalEditarEquipoOpen(false);
  };

  const handleAddEquipoClick = () => {
    setFormEquipoData({
      ...formEquipoData,
      ["id_usuario"]: dataLogin.id_usuario,
    });

    setModalEquipoOpen(true);
  };

  const handleSubmitEquipo = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioEquipo();

      if (esFormularioValido) {
        const response = await fetch(
          `https://api.vittamaq.cl/equipo.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formEquipoData),
          }
        );
  
        const data = await response.json();
  
        if (data.message === "Equipo agregado con éxito.") {
          alert("Equipo agregado con éxito.");
          setFormEquipoData({
            id_maquinaria: "",
            marca: "",
            modelo: "",
            numero_serie: "",
            id_ano_equipo: "",
            valor_minimo_arriendo: "",
            valor_arriendo_mensual: "",
            horometro: "",
            caracteristicas: "",
            id_usuario: ""
          });
          navigate("/equipos", { state: dataLogin });
          closeModalEquipo();
        } else {
          throw new Error();
        }
      } 
    } catch (error) {
      alert("Error al agregar el equipo.");
    }
  };

  const validaFormularioEquipo = () => {
    if (formEquipoData.id_maquinaria === "") {
      alert('Debe seleccionar un tipo de maquinaria');
      return false;
    }

    if (formEquipoData.marca === "") {
      alert('Debe ingresar una marca');
      return false;
    }

    if (formEquipoData.modelo === "") {
      alert('Debe ingresar un modelo');
      return false;
    }

    if (formEquipoData.numero_serie === "") {
      alert('Debe ingresar un número de serie');
      return false;
    }

    if (formEquipoData.id_ano_equipo === "") {
      alert('Debe ingresar un año');
      return false;
    }

    if (formEquipoData.valor_minimo_arriendo === "") {
      alert('Debe ingresar un valor mínimo de arriendo');
      return false;
    }

    if (formEquipoData.valor_arriendo_mensual === "") {
      alert('Debe ingresar un valor de arriendo mensual');
      return false;
    }

    if (formEquipoData.horometro === "") {
      alert('Debe ingresar un valor de horómetro');
      return false;
    }

    if (formEquipoData.caracteristicas === "") {
      alert('Debe ingresar las características de la maquinaria');
      return false;
    }

    return true;
  }

  //Editar Maquinaria
  const [formEquipoEditData, setFormEquipoEditData] = useState({
    id_equipo: "",
    id_maquinaria: "",
    marca: "",
    modelo: "",
    numero_serie: "",
    id_ano_equipo: "",
    valor_minimo_arriendo: "",
    valor_arriendo_mensual: "",
    horometro: "",
    caracteristicas: "",
    id_usuario_actualizacion: dataLogin.id_usuario
  });

  const handleEditarEquipoClick = async (equipo) => { 
    setFormEquipoEditData({
      ...formEquipoEditData,
      ["id_equipo"]: equipo.id_equipo,
      ["id_maquinaria"]: equipo.id_maquinaria || "",
      ["marca"]: equipo.marca || "",
      ["modelo"]: equipo.modelo || "",
      ["numero_serie"]: equipo.numero_serie || "",
      ["id_ano_equipo"]: equipo.id_ano_equipo || "",
      ["valor_minimo_arriendo"]: equipo.valor_minimo_arriendo || "",
      ["valor_arriendo_mensual"]: equipo.valor_arriendo_mensual || "",
      ["horometro"]: equipo.horometro || "",
      ["caracteristicas"]: equipo.caracteristicas || "",
      ["id_usuario_actualizacion"]: dataLogin.id_usuario
    });

    setModalEditarEquipoOpen(true);
  };

  const handleFormEditarEquipoData = (e) => {
    switch (e.target.name) {
      case "id_maquinaria":
        setEquipoSelected(e.target.value);

        setFormEquipoEditData({
          ...formEquipoEditData,
          [e.target.name]: e.target.value,
        });
        break;
      case "valor_minimo_arriendo":
        if (/^\d*$/.test(e.target.value)) {
          setFormEquipoEditData({
            ...formEquipoEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "valor_arriendo_mensual":
        if (/^\d*$/.test(e.target.value)) {
          setFormEquipoEditData({
            ...formEquipoEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "horometro":
        if (/^\d*([\.,]\d*)?$/.test(e.target.value)) {
          setFormEquipoEditData({
            ...formEquipoEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      default:
        setFormEquipoEditData({
          ...formEquipoEditData,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleSubmitEditEquipo = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioEditarEquipo();

      if (esFormularioValido) {
        const responseEditEquipo = await fetch(
          `https://api.vittamaq.cl/equipo.php`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formEquipoEditData),
          }
        );

        const dataEditEquipo = await responseEditEquipo.json();
        if (dataEditEquipo.message === "Equipo modificado con éxito.") {
          obtenerEquipo();

          alert("Equipo modificado con éxito.");

          closeModalEditarEquipo();

          navigate("/equipos", { state: dataLogin });
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      alert("Error al editar el equipo.");
    }
  }

  const validaFormularioEditarEquipo = () => {
    if (formEquipoEditData.id_maquinaria === "") {
      alert('Debe seleccionar un tipo de maquinaria');
      return false;
    }

    if (formEquipoEditData.marca === "") {
      alert('Debe ingresar una marca');
      return false;
    }

    if (formEquipoEditData.modelo === "") {
      alert('Debe ingresar un modelo');
      return false;
    }

    if (formEquipoEditData.valor_minimo_arriendo === "") {
      alert('Debe ingresar un valor mínimo de arriendo');
      return false;
    }

    if (formEquipoEditData.valor_arriendo_mensual === "") {
      alert('Debe ingresar un valor de arriendo mensual');
      return false;
    }

    if (formEquipoEditData.horometro === "") {
      alert('Debe ingresar un valor de horómetro');
      return false;
    }

    if (formEquipoEditData.caracteristicas === "") {
      alert('Debe ingresar las características del equipo');
      return false;
    }

    return true;
  }

  const modalComponentEquipo = (
    <Modal
      isOpen={isModalEquipoOpen}
      onRequestClose={closeModalEquipo}
      contentLabel="ModalEquipo"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Equipo</h4>
            <button onClick={closeModalEquipo} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label className="form-label">Maquinaria</label>
              <select className="form-select" name="id_maquinaria" value={formEquipoData.id_maquinaria} onChange={handleFormEquipoData}>
                <option value="">Seleccione Maquinaria</option>
                {maquinariaOptions.map((maquinaria) => (
                  <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                ))}
              </select>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Marca</label>
                <input
                  className="form-control"
                  name="marca"
                  value={formEquipoData.marca}
                  onChange={handleFormEquipoData}
                  type="text"
                />
              </div>
              <div className="col">
                <label className="form-label">Modelo</label>
                <input
                  className="form-control"
                  name="modelo"
                  value={formEquipoData.modelo}
                  onChange={handleFormEquipoData}
                  type="text"
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">N° Serie</label>
                <input
                  className="form-control"
                  name="numero_serie"
                  value={formEquipoData.numero_serie}
                  onChange={handleFormEquipoData}
                  type="text"
                />
              </div>
              <div className="col">
                <label className="form-label">Año</label>
                <select className="form-select" name="id_ano_equipo" value={formEquipoData.id_ano_equipo} onChange={handleFormEquipoData}>
                  <option value="">Seleccione Año</option>
                  {anoEquipo.map((ano_equipo, index) => (
                    <option key={index} value={ano_equipo.id_ano_equipo}>{ano_equipo.ano_equipo}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Valor Minimo de Arriendo {equipoSelected === '2' ? '$' : 'UF'}</label>
                <input
                  className="form-control"
                  name="valor_minimo_arriendo"
                  value={formEquipoData.valor_minimo_arriendo}
                  onChange={handleFormEquipoData}
                  type="text"
                />
              </div>
              <div className="col">
                <label className="form-label">Valor de Arriendo Mensual {equipoSelected === '2' ? '$' : 'UF'}</label>
                <input
                  className="form-control"
                  name="valor_arriendo_mensual"
                  value={formEquipoData.valor_arriendo_mensual}
                  onChange={handleFormEquipoData}
                  type="text"
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Horómetro</label>
                <input
                  className="form-control"
                  name="horometro"
                  value={formEquipoData.horometro}
                  onChange={handleFormEquipoData}
                  type="text"
                />
              </div>
            </div>
            <label className="form-label">Características</label>
            <textarea
              className="form-control"
              name="caracteristicas"
              value={formEquipoData.caracteristicas}
              onChange={handleFormEquipoData}
              type="textarea"
              rows="4"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEquipo}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  //Editar Equipo
  const modalComponentEditarEquipo = (
    <Modal
      isOpen={isModalEditarEquipoOpen}
      onRequestClose={closeModalEditarEquipo}
      contentLabel="ModalEditarEquipo"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Equipo</h4>
            <button onClick={closeModalEditarEquipo} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label className="form-label">Maquinaria</label>
              <select className="form-select" name="id_maquinaria" value={formEquipoEditData.id_maquinaria} onChange={handleFormEditarEquipoData} disabled>
                <option value="">Seleccione Maquinaria</option>
                {maquinariaOptions.map((maquinaria) => (
                  <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                ))}
              </select>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Marca</label>
                <input
                  className="form-control"
                  name="marca"
                  value={formEquipoEditData.marca}
                  onChange={handleFormEditarEquipoData}
                  type="text"
                />
              </div>
              <div className="col">
                <label className="form-label">Modelo</label>
                <input
                  className="form-control"
                  name="modelo"
                  value={formEquipoEditData.modelo}
                  onChange={handleFormEditarEquipoData}
                  type="text"
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">N° Serie</label>
                <input
                  className="form-control"
                  name="numero_serie"
                  value={formEquipoEditData.numero_serie}
                  onChange={handleFormEditarEquipoData}
                  type="text"
                />
              </div>
              <div className="col">
                <label className="form-label">Año</label>
                <select className="form-select" name="id_ano_equipo" value={formEquipoEditData.id_ano_equipo} onChange={handleFormEditarEquipoData}>
                  <option value="">Seleccione Año</option>
                  {anoEquipo.map((ano_equipo, index) => (
                    <option key={index} value={ano_equipo.id_ano_equipo}>{ano_equipo.ano_equipo}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Valor Minimo de Arriendo {equipoSelected === '2' ? '$' : 'UF'}</label>
                <input
                  className="form-control"
                  name="valor_minimo_arriendo"
                  value={formEquipoEditData.valor_minimo_arriendo}
                  onChange={handleFormEditarEquipoData}
                  type="text"
                />
              </div>
              <div className="col">
                <label className="form-label">Valor de Arriendo Mensual {equipoSelected === '2' ? '$' : 'UF'}</label>
                <input
                  className="form-control"
                  name="valor_arriendo_mensual"
                  value={formEquipoEditData.valor_arriendo_mensual}
                  onChange={handleFormEditarEquipoData}
                  type="text"
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="mb-3">
                <label className="form-label">Horómetro</label>
                <input
                  className="form-control"
                  name="horometro"
                  value={formEquipoEditData.horometro}
                  onChange={handleFormEditarEquipoData}
                  type="text"
                />
              </div>
            </div>
            <label className="form-label">Características</label>
            <textarea
              className="form-control"
              name="caracteristicas"
              value={formEquipoEditData.caracteristicas}
              onChange={handleFormEditarEquipoData}
              type="textarea"
              rows="4"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEditEquipo}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header d-flex flex-column flex-md-row justify-content-md-between align-items-md-center border-bottom-0 p-3 bg-white">
                  <h4 className="mb-2 mb-lg-0">Equipos</h4>
                  <div className="d-flex flex-column flex-md-row gap-md-2">
                    <select className="form-select form-select-width mb-2 mb-md-0" name="id_maquinaria" value={equipoFiltro} onChange={handleFiltroEquipoData}>
                      {maquinariaOptions.map((maquinaria) => (
                        <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                      ))}
                    </select>
                    <div className="input-group w-auto mb-2 mb-md-0">
                      <span className="input-group-text" id="search-servicios">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Modelo Equipo..." aria-describedby="search-equipo" onChange={manejarCambioFiltro}/>
                    </div>
                    <button className="btn btn-yellow" onClick={handleAddEquipoClick}>
                      <i className="bi-plus-circle"></i> Agregar Equipo
                    </button>
                  </div>
                </div>
                <div className="card-body card-body-fh p-0">
                  <div className="table-responsive">
                    <table className="table table-hover mb-0">
                      <thead>
                        <tr className="table-active border-top border-bottom">
                          <th scope="col" className="ps-3">Maquinaria</th>
                          <th scope="col">Iniciales</th>
                          <th scope="col">Marca</th>
                          <th scope="col">Modelo</th>
                          <th scope="col">N° Serie</th>
                          <th scope="col">Año</th>
                          <th scope="col">Valor Mínimo Arriendo {equipoFiltro === '2' ? '$' : 'UF'}</th>
                          <th scope="col">Valor Mensual Arriendo {equipoFiltro === '2' ? '$' : 'UF'}</th>
                          <th scope="col">Horómetro</th>
                          <th scope="col">Características</th>
                          <th scope="col">Edición</th>
                        </tr>
                      </thead>
                      <tbody>
                        {equiposFiltrados.length > 0 ? (
                          equiposFiltrados.map((equipo, index) => (
                            <tr key={index}>
                              <td className="ps-3">{equipo.nombre_maquinaria}</td>
                              <td>{equipo.iniciales}</td>
                              <td>{equipo.marca}</td>
                              <td>{equipo.modelo}</td>
                              <td>{equipo.numero_serie}</td>
                              <td>{equipo.ano_equipo}</td>
                              <td><small className="text-secondary">{equipo.id_maquinaria === 2 ? '$' : ''} </small> {Number(equipo.valor_minimo_arriendo).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td><small className="text-secondary">{equipo.id_maquinaria === 2 ? '$' : ''} </small> {Number(equipo.valor_arriendo_mensual).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td>{equipo.horometro}</td>
                              <td>{equipo.caracteristicas}</td>
                              <td className="pe-3">
                                <button className="btn btn-outline-dark btn-sm w-100" onClick={() => handleEditarEquipoClick(equipo)}>
                                  <i className="bi bi-pencil-square"></i> Editar
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={11}>No se encontraron equipos</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalComponentEquipo}
      {modalComponentEditarEquipo}
    </div>
  );
}

const styles = {
  mainContainer: {
    /*display: "flex",
    flexDirection: "row",*/
    height: "100vh",
  },
  lineCliente: {
    width: "5px",
    height: "100%",
    backgroundColor: "#e84424",
    borderRadius: "3px",
  },
  lineComentario: {
    width: "5px",
    height: "100%",
    backgroundColor: "#00a7b5",
    borderRadius: "3px",
  },
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "1.5rem 1.5rem 1.5rem",
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#f7f9fc",
    boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.12)",
    width: "30%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default Equipos;
