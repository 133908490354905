import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import './App.css';

function ReporteVenta() {
  const location = useLocation();
  const [dataLogin, setDataLogin] = useState([]);
  const [facturaciones, setFacturaciones] = useState([]);
  const [maquinariasOptions, setMaquinariasOptions] = useState([]);
  const [clientesOptions, setClientesOptions] = useState([]);
  const [equiposOptions, setEquiposOptions] = useState([]);
  const [hormigonerasOptions, setHormigonerasOptions] = useState([]);
  const [opcionesHormigonera, setOpcionesHormigonera] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerMaquinarias();
      obtenerClientes();
      obtenerHormigoneras();
    }
  }, [location]);
  
  let opcionesHormigoneraarray = [];
  const obtenerHormigoneras = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/hormigonera.php');
      const data = await response.json();
      
      if(data && data[0].nombre_homigonera){
        // console.log(JSON.stringify(data,null,2));
        opcionesHormigoneraarray = data.map((hormigonera, index) => ({
          id: index + 1, // Generar un ID autogenerado basado en el índice (comenzando desde 1)
          nombre: hormigonera.nombre_homigonera // Mantener el nombre de la hormigonera
        }));
        setOpcionesHormigonera(opcionesHormigoneraarray);
        // console.log("opcionesHormigoneraarray  "+JSON.stringify(opcionesHormigoneraarray,null,2));
        setHormigonerasOptions(data);
      }
    } catch (error) {
      setHormigonerasOptions([]);
    }
  };

  const obtenerMaquinarias = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/maquinaria.php');
      const data = await response.json();

      if(data && data[0].id_maquinaria){
        
        /* const maquinariaBH = data.find(maquinaria => maquinaria.id_maquinaria == "1");
        if (maquinariaBH) {
          setMaquinariasOptions([maquinariaBH]);
        } */
        setMaquinariasOptions(data);
      }
    } catch (error) {
      setMaquinariasOptions([]);
    }
  };

  const obtenerClientes = async () => {
    try {
      const response = await fetch('https://api.vittamaq.cl/cliente.php');
      const data = await response.json();

      if(data && data[0].id_cliente){
        // console.log(data);
        setClientesOptions(data);
      }
    } catch (error) {
      setClientesOptions([]);
    }
  };

  const obtenerEquipos = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/equipo.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();
      
      if(data && data[0].id_equipo){
        //const equiposBH = data.filter(equipo => ["Telescópica", "Estacionaria"].includes(equipo.descripcion));
        // console.log(equiposBH);
        /* if (equiposBH) {
          setEquiposOptions(equiposBH);
        } */
        // console.log(data);
        setEquiposOptions(data);
      }
    } catch (error) {
      setEquiposOptions([]);
    }
  };

  const [clienteSelected, setClienteSelected] = useState('');
  const [obras, setObras] = useState([]);

  const handleClienteSelect = async (clienteSelected) => {
    setClienteSelected(clienteSelected);
    await obtenerObras(clienteSelected);

    setFormConsultaData({
      ...formConsultaData,
      ["id_cliente"]: clienteSelected,
    });

    //console.log(formConsultaData);
  };
  
  const obtenerObras = async (cliente) => {
    try {
      const response = await fetch(
        `https://api.vittamaq.cl/reporte_estado_pago.php?id_cliente=${cliente}`
      );
      const data = await response.json();
      if (data && data[0].id_obra) {
        setObras(data);
      }
    } catch (error) {
      setObras([]);
    }
  };

  //Filtro 
  const [filtroFacturaciones, setFiltroEstadoPagoBH] = useState('');
  const manejarCambioFiltro = (event) => {
    setFiltroEstadoPagoBH(event.target.value);
  };

  const facturacionesFiltradas = facturaciones.filter((item) =>
    item.nombre_obra.toLowerCase().includes(filtroFacturaciones.toLowerCase())
  );

  const currentDate = new Date().toISOString().split('T')[0];
  const [formConsultaData, setFormConsultaData] = useState({
    id_maquinaria: 0,
    id_cliente: 0,
    id_obra: 0,
    fecha_inicio: currentDate,
    fecha_fin: currentDate,
    equipos: 0,
    hormigonera:""
  });

  const [maquinariaSelect, setMaquinariaSelect] = useState('');
  const [hormigoneraSelect, setHormigoneraSelect] = useState('');

  const handleFormConsultaData = async (e) => {
    const { name, value } = e.target;

    setFormConsultaData({
      ...formConsultaData,
      [name]: value,
    });
  };
  /* useEffect(() => {
    console.log(JSON.stringify(formConsultaData,null,2));
  }, [formConsultaData]); */

  const handleMaquinariaSelect = async (maquinariaSelect) => {
    setMaquinariaSelect(maquinariaSelect);
    await obtenerEquipos(maquinariaSelect);

    setFormConsultaData({
      ...formConsultaData,
      ["id_maquinaria"]: maquinariaSelect,
    });

    //console.log(formConsultaData);
  };

  const handleHormigoneraSelect = async (hormigoneraSelect) => {
    setHormigoneraSelect(hormigoneraSelect);
    const hormigoneraSeleccionada = opcionesHormigonera.find(option => option.id == hormigoneraSelect);
    const nombreHormigonera = hormigoneraSeleccionada ? hormigoneraSeleccionada.nombre : ''; // Obtén el nombre de la hormigonera seleccionada

    setFormConsultaData({
      ...formConsultaData,
      ["hormigonera"]: nombreHormigonera,
    });

    //console.log(formConsultaData);
  };
  const handleBuscarFacturacionClick = async (event) => {
    event.preventDefault();

    try {
      //console.log(JSON.stringify(formConsultaData,null,2));
      const responseRequerimiento = await fetch(
        `https://api.vittamaq.cl/reporte_facturacion.php`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formConsultaData),
        }
      );

      const dataRequerimiento = await responseRequerimiento.json();
      if (dataRequerimiento && dataRequerimiento[0].id_estado_pago) {
        setFacturaciones(dataRequerimiento);
      }

    } catch (error) {
      setFacturaciones([]);
    }
  }

  const exportToExcel = () => {
    const ahora = new Date();
    const fechaFormateada = formatearFecha(ahora);

    const arregloExportar = facturaciones.map(obj => {
      return {
        ["Maquinaria"]: obj.maquinaria,
        ["Cliente"]: obj.nombre_cliente,
        ["Obra"]: obj.nombre_obra,
        ["Folio Cotización"]: obj.folio_cotizacion,
        ["Fecha Estado Pago"]: obj.fecha_estado_pago,
        ["Fecha Facturación"]: obj.fecha_facturacion,
        ["Folio Kame"]: obj.folio_kame,
        ["Total Neto"]: Number(Math.round(obj.total_neto)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["Iva"]: Number(Math.round(obj.iva)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["Descuentos"]: Number(Math.round(obj.otros_descuentos)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["Total"]: Number(Math.round(obj.total)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arregloExportar);
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte de venta');
    XLSX.writeFile(wb, 'Facturación ' + fechaFormateada + '.xlsx');
  };

  const formatearFecha = (fecha) => {
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const year = fecha.getFullYear();
    const hora = String(fecha.getHours()).padStart(2, '0');
    const minutos = String(fecha.getMinutes()).padStart(2, '0');

    return `${dia}-${mes}-${year} ${hora}_${minutos}`;
  };

  const formatearRut = (rut) => {
    const rutFormateado = `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(5)}`;
    return rutFormateado;
  };

  /* Modals */
  const handleModalCotizacion = (folioCotizacion) => {
    setModalContent(<CotizacionModalContent folio={folioCotizacion} />);
    setModalOpen(true);
  };

  /* const handleModalEstadoPago = (tipo, folioEstadoPago, idEstadoPago) => {
    setModalOpen(true);
    switch (tipo) {
      case "Bomba Hormigón":
        setModalContent(<EstadoPagoBHModalContent folio={folioEstadoPago} id_estado_pago={idEstadoPago} />);
        break;
      case "Grúa Hidráulica":
        setModalContent(<EstadoPagoGHModalContent id_estado_pago={idEstadoPago} />);
        break;
      default:
        setModalContent(<EstadoPagoGTModalContent id_estado_pago={idEstadoPago} />);
    }
  }; */

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  function CotizacionModalContent({ folio }) {
    const [cabeceraCotizacion, setCabeceraCotizacion] = useState([]);
    const [detalleCotizacion, setdetalleCotizacion] = useState([]);

    useEffect(() => {
      obtenerCabeceraCotizacion(folio);
      obtenerDetalleCotizacion(folio);
    }, []);

    const obtenerCabeceraCotizacion = async (folio) => {
      try {
        const response = await fetch(
          `https://api.vittamaq.cl/cabecera_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();

        if (data && data[0].folio) {
          setCabeceraCotizacion(data);
        }
      } catch (error) {
        setCabeceraCotizacion([]);
      }
    };

    const obtenerDetalleCotizacion = async (folio) => {
      try {
        const response = await fetch(
          `https://api.vittamaq.cl/detalle_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();

        if (data && data[0].folio) {
          setdetalleCotizacion(data);
        }
      } catch (error) {
        setdetalleCotizacion([]);
      }
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Cotización {folio}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{folio || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Cotización</span>
                    <span className="label">{cabeceraCotizacion[0]?.fecha_cotizacion || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Obra</span>
                    <span className="label">{cabeceraCotizacion[0]?.nombre_obra || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Dirección Obra</span>
                    <span className="label">{cabeceraCotizacion[0]?.direccion_obra || ""} ({cabeceraCotizacion[0]?.comuna_obra || ""})</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Maquinaria | Equipo</span>
                    <span className="label">{cabeceraCotizacion[0]?.nombre_maquinaria || ""} | {cabeceraCotizacion[0]?.equipo === "null null" ? "" : cabeceraCotizacion[0]?.equipo}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{cabeceraCotizacion[0]?.nombre_cliente || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{cabeceraCotizacion[0]?.rut_cliente ? formatearRut(cabeceraCotizacion[0]?.rut_cliente) : ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente Final</span>
                    <span className="label">{cabeceraCotizacion[0]?.nombre_cliente_final || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Contacto</span>
                    <span className="label">{cabeceraCotizacion[0]?.email_contacto || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Inicio | Fin</span>
                    <span className="label">{cabeceraCotizacion[0]?.fecha_inicio || ""} | {cabeceraCotizacion[0]?.fecha_fin || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-lg-2">
              <div className="col">
                <h5 className="mb-3">Solicitante</h5>
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Nombre</span>
                    <span className="label">{cabeceraCotizacion[0]?.nombre_solicitante || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{cabeceraCotizacion[0]?.rut_solicitante ? formatearRut(cabeceraCotizacion[0]?.rut_solicitante) : ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Email</span>
                    <span className="label">{cabeceraCotizacion[0]?.email_solicitante || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Requerimiento</h5>
                <ul className="list-group mb-3">
                  <li className="list-group-item">
                    <span className="label">{cabeceraCotizacion[0]?.nombre_requerimiento || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <h5 className="mb-3">Observaciones</h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="label">{cabeceraCotizacion[0]?.observaciones ? cabeceraCotizacion[0]?.observaciones : "Sin observaciones"}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Servicios</h5>
            <div className="table-responsive border rounded">
              <table className="table table-hover mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th scope="col" className="ps-3">Item</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Cantidad Mínima</th>
                    <th scope="col">Unidad Medida</th>
                    <th scope="col">
                      Valor Unitario Neto más IVA
                      {cabeceraCotizacion[0]?.id_maquinaria === 2 ? " ($)" : " (UF)"}
                    </th>
                    {cabeceraCotizacion[0]?.id_maquinaria === 1 && (
                      <th scope="col" className="pe-3">Valor Unitario Neto PM (UF)</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {detalleCotizacion ? (
                    detalleCotizacion.map((detalle, index) => (
                      <tr key={index}>
                        <td className="ps-3">
                          {detalle.item_orden.split('-')[1]}
                        </td>
                        <td>
                          {
                            detalle.descripcion !== null && detalle.descripcion !== '' ? (
                              detalle.descripcion
                            ) : (
                              detalle.id_maquinaria !== 1 && detalle.id_maquinaria !== 2 && detalle.id_tipo_maquinaria === 0 ? (
                                detalle.nombre_maquinaria + " - " + detalle.tipo_arriendo
                              ) : (
                                detalle.nombre_maquinaria + (detalle.tipo_maquinaria === "Ninguno" ? " - " + detalle.tipo_arriendo : " - " + detalle.tipo_maquinaria + " - " + detalle.tipo_arriendo)
                              )
                            )
                          }
                        </td>
                        <td>{Math.trunc(detalle.cantidad_minima)}</td>
                        <td>{detalle.unidad_medida}</td>
                        <td>
                          {
                            detalle.id_maquinaria === 1 ? (
                              detalle.valor_unitario_neto
                            ) : detalle.id_maquinaria === 2 ? (
                              parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
                            ) : (
                              parseFloat(detalle.valor_unitario_neto).toFixed(2)
                            )
                          }
                        </td>
                        {detalle.id_maquinaria === 1 && detalle.id_tipo_maquinaria != null && (detalle.id_unidad_medida === 1 || detalle.id_unidad_medida === 7) ? (
                          <td className="pe-3">{detalle.valor_unitario_neto_pm}</td>
                        ) : detalle.id_maquinaria != 1 ?(
                          null
                        ) : <td></td> }
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>No se encontraron servicios asociados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="d-flex gap-md-2 mb-3">
                <div>
                  <label className="form-label">Maquinaria</label>
                  <select className="form-select" name="id_tipo_maquinaria" value={formConsultaData.id_maquinaria} onChange={(e) => handleMaquinariaSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {maquinariasOptions.map((maquinaria, index) => (
                      <option key={index} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Equipo</label>
                  {equiposOptions.length > 0 ? (
                  <select className="form-select" name="equipos" value={formConsultaData.equipos} onChange={handleFormConsultaData}>
                    <option value="0">Todos</option>
                    {equiposOptions.map((equipo) => (
                      <option key={equipo.id_equipo} value={equipo.id_equipo}>{equipo.marca + " " + equipo.modelo}</option>
                    ))}
                  </select>
                  ) : (
                    <select className="form-select" name="equipos">
                      <option value="0">Todos</option>
                    </select>
                    )}
                </div>
                <div>
                  <label className="form-label">Hormigonera</label>
                  <select className="form-select" name="hormigoneras" value={hormigoneraSelect} onChange={(e) => handleHormigoneraSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {/* {hormigonerasOptions.map((hormigonera) => (
                      <option key={hormigonera.id} value={hormigonera.id}>{hormigonera.nombre_homigonera}</option>
                    ))} */}
                    {hormigonerasOptions.map((hormigonera, index) => (
                      <option key={index+1} value={index+1}>{hormigonera.nombre_homigonera}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Cliente</label>
                  <select className="form-select" name="cliente" value={clienteSelected} onChange={(e) => handleClienteSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {clientesOptions.map((cliente) => (
                      <option key={cliente.id_cliente} value={cliente.id_cliente}>{cliente.nombre_cliente}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Obra</label>
                  {obras.length > 0 ? (
                    <select className="form-select" name="id_obra" value={formConsultaData.id_obra} onChange={handleFormConsultaData}>
                      <option value="0">Todos</option>
                      {obras.map((obra) => (
                        <option key={obra.id_obra} value={obra.id_obra}>{obra.nombre}</option>
                      ))}
                    </select>
                  ) : (
                    <select className="form-select" name="id_obra">
                      <option value="0">Todos</option>
                    </select>
                  )}
                </div>  
                <div>
                  <label className="form-label">Fecha Inicio</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_inicio"
                    value={formConsultaData.fecha_inicio}
                    onChange={handleFormConsultaData}
                  />
                </div>
                <div className="">
                  <label className="form-label">Fecha Fin</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_fin"
                    value={formConsultaData.fecha_fin}
                    onChange={handleFormConsultaData}
                  />
                </div>     
                <div>
                  <label className="form-label d-block">&nbsp; </label>
                  <button className="btn btn-yellow" onClick={handleBuscarFacturacionClick}>
                    <i className="bi bi-search"></i> Buscar
                  </button>
                </div>   
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Reporte Facturación</h4>
                  <div className="d-flex gap-md-2">
                    <div className="input-group w-auto">
                      <span className="input-group-text" id="search-folio">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Obra..." aria-describedby="search-folio" onChange={manejarCambioFiltro}/>
                    </div>
                    {facturaciones.length > 0 ? (
                      <button className="btn btn-success" onClick={exportToExcel}><i className="bi bi-download"></i> Exportar Excel</button>
                    ) : (
                      <button className="btn btn-success" disabled><i className="bi bi-download"></i> Exportar Excel</button>
                    )}
                  </div>
                </div>
                <div className="card-body card-body-filter border-bottom-radius p-0">
                  <div className="table-responsive">
                    <table className="table table-hover mb-0">
                      <thead>
                        <tr className="table-active border-top border-bottom">
                          <th scope="col" className="ps-3">Maquinaria</th>
                          <th scope="col">Cliente</th>
                          <th scope="col">Obra</th>
                          <th scope="col">Folio Cotización</th>
                          <th scope="col">Fecha Estado Pago</th>
                          <th scope="col">Fecha Facturación</th>
                          <th scope="col">Folio Kame</th>
                          <th scope="col">Total Neto</th>
                          <th scope="col">Iva</th>
                          <th scope="col">Otros Descuentos</th>
                          <th scope="col" className="pe-3">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {facturacionesFiltradas.length > 0 ? (
                          facturacionesFiltradas.map((facturacion, index) => (
                            <tr key={index}>
                              <td className="ps-3">{facturacion.maquinaria}</td>
                              <td>{facturacion.nombre_cliente}</td>
                              <td>{facturacion.nombre_obra}</td>
                              <td>
                                <button className="btn btn-link p-0" onClick={() => handleModalCotizacion(facturacion.folio_cotizacion)}>{facturacion.folio_cotizacion}</button>
                              </td>
                              {/* <td>
                                <button className="btn btn-link p-0" onClick={() => handleModalEstadoPago(facturacion.maquinaria, facturacion.folio_cotizacion, facturacion.id_estado_pago)}>{facturacion.fecha_estado_pago}</button>
                              </td> */}
                              <td>
                                {facturacion.fecha_estado_pago}
                              </td>
                              <td>{facturacion.fecha_facturacion}</td>
                              <td>{facturacion.folio_kame}</td>
                              <td>{Number(Math.round(facturacion.total_neto)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td>{Number(Math.round(facturacion.iva)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td>{Number(Math.round(facturacion.otros_descuentos)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td className="pe-3"><span className="text-orange fw-bold">{Number(Math.round(facturacion.total)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span></td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={10}>No se encontró facturación</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>
    </div>
  );
}

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default ReporteVenta;
